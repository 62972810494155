<template>
  <div>
    <div class="d-flex justify-content-between my-auto">
      <div>
        <h5 class="font-weight-bold mb-1">
          Certification, Authentication & Verification (CAV) Application
        </h5>
        <span class="form-text text-muted mt-0" style="font-size:13px;"
          >We would love to hear your thoughts, suggestions, concerns/problems
          with anything so we can improve! Click
          <a
            href="https://docs.google.com/forms/d/e/1FAIpQLSfCW65mC9LBS-kuyhPmspdG9oQ1PF5i3PeeVVLDik1u0R6VJg/viewform"
            target="_blank"
            >here</a
          >.</span
        >
      </div>

      <button class="btn btn-primary" @click="openApplicationFormModal">
        SUBMIT APPLICATION
      </button>
    </div>

    <div
      class="alert alert-success rounded shadow alert-dismissible fade show mt-4"
      role="alert"
    >
      <h5>
        <strong
          >Great news! Our SMS Notification Service is now up and running.
        </strong>
      </h5>
      <span
        >The applicants can now receive their <strong>APP NO</strong> through
        their registered mobile numbers. Thank you for your understanding during
        the temporary downtime.

        <span class="form-text">- July 21, 2023 11:51 AM</span>
      </span>
      <ul class="pl-3">
        <!--     <li>
          Effective January 16, 2023, we will be limiting the number of CAV
          applications per day. This is to ensure that we continue to
          efficiently and effectively process your requests within the day.
          Thank you for your usual cooperation.
        </li> -->
        <!--        <li>
          <strong>FEBRUARY 1, 2023 - PAYMENT UPDATE </strong>Effective Dec. 05,
          2022, our payment process has been updated. All payments shall be made
          via the LBP Link.BizPortal. Click
          <a
            href="https://drive.google.com/file/d/1-9eXgQ6yeH1WoOZBaTtm0a6Hdd_q5Dyv/view?usp=sharing"
            target="_blank"
            class="font-weight-bold"
            >here</a
          >
          for a full step-by-step guide of the new process.
        </li> -->
      </ul>

      <button
        type="button"
        class="close"
        data-dismiss="alert"
        aria-label="Close"
      >
        <span aria-hidden="true">&times;</span>
      </button>
    </div>

    <div class="card shadow-sm rounded mt-4">
      <div class="card-body">
        <form
          @submit.prevent="
            getCavApplications(DEFAULT_URL, {
              tableLoader: { enabled: false },
              searchLoader: { enabled: true },
            })
          "
        >
          <div class="card-title font-weight-bold" style="font-size: 1rem">
            Search or filter applications
          </div>
          <div class="form-row">
            <div class="col-1">
              <div class="form-group">
                <label for="">App No</label>
                <input
                  type="search"
                  class="form-control"
                  placeholder="App No"
                  v-model="params.id"
                />
              </div>
            </div>
            <div class="col-3">
              <div class="form-group">
                <label for="">Last Name</label>
                <input
                  type="search"
                  class="form-control"
                  placeholder="Search by Last Name"
                  v-model="params.lastname"
                />
              </div>
            </div>
            <div class="col">
              <div class="form-group">
                <label for="">Status</label>
                <select
                  name=""
                  id=""
                  class="form-control"
                  v-model="params.status_id"
                >
                  <option value="">All</option>
                  <option value="1">For validation</option>
                  <option value="2">For payment</option>
                  <option value="3">For verification</option>
                  <option value="4">For process</option>
                  <option value="5">For release</option>
                  <option value="6">Released</option>
                  <option value="7">Pending</option>
                  <option value="8">Pending payment</option>
                </select>
              </div>
            </div>
            <div class="col">
              <div class="form-group">
                <label for="">Type</label>
                <select
                  name=""
                  id=""
                  class="form-control"
                  v-model="params.student_type"
                >
                  <option value="">All</option>
                  <option value="GR">Graduate (GR)</option>
                  <option value="UE">Unit Earner (UE)</option>
                </select>
              </div>
            </div>
            <div class="col">
              <div class="form-group">
                <label for="">Purpose</label>
                <select
                  name=""
                  id=""
                  class="form-control"
                  v-model="params.purpose"
                >
                  <option value="">All</option>
                  <option value="Local">Local</option>
                  <option value="Abroad">Abroad</option>
                </select>
              </div>
            </div>
            <div class="col">
              <div class="form-group">
                <label for="">Date Applied</label>
                <input
                  type="date"
                  class="form-control"
                  v-model="params.created_at"
                />
              </div>
            </div>
            <div class="col">
              <div class="form-group">
                <label for="" class="invisible">Button</label>
                <button
                  type="submit"
                  class="form-control btn btn-primary"
                  style="background-color:#321fdb; color: #fff"
                >
                  <span v-if="!searchLoader">SEARCH</span>
                  <span
                    v-else
                    class="spinner-border spinner-border-sm mr-1"
                  ></span>
                </button>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>

    <div class="card shadow-sm rounded">
      <div class="card-body">
        <div class="d-flex justify-content-between mb-3">
          <div class="form-inline">
            <div class="form-group">
              <label for="">No. of rows</label>
              <select
                name=""
                id=""
                class="form-control ml-1"
                v-model="params.per_page"
                @change="
                  getCavApplications(DEFAULT_URL, {
                    tableLoader: { enabled: false },
                    searchLoader: { enabled: false },
                  })
                "
              >
                <option value="10">10</option>
                <option value="30">30</option>
                <option value="50">50</option>
                <option value="100">100</option>
              </select>
            </div>
          </div>
          <div></div>
          <div class="d-flex justify-content-right align-items-baseline">
            <div class="my-auto mr-2">
              <span
                >{{
                  `${pagination.from || 0}-${pagination.to ||
                    0} of ${pagination.total || 0}`
                }}
              </span>
            </div>
            <nav>
              <ul class="pagination mb-0 pagination-sm">
                <li
                  class="page-item"
                  :class="{ disabled: !pagination.prev_page_url }"
                >
                  <a
                    class="page-link"
                    href="#"
                    @click.prevent="
                      getCavApplications(pagination.prev_page_url, {
                        tableLoader: { enabled: false },
                        searchLoader: { enabled: false },
                      })
                    "
                  >
                    <span aria-hidden="true"
                      ><i class="bi bi-chevron-left"></i
                    ></span>
                    <span class="sr-only">Previous</span>
                  </a>
                </li>

                <li
                  class="page-item"
                  :class="{ disabled: !pagination.next_page_url }"
                >
                  <a
                    class="page-link"
                    href="#"
                    @click.prevent="
                      getCavApplications(pagination.next_page_url, {
                        tableLoader: { enabled: false },
                        searchLoader: { enabled: false },
                      })
                    "
                  >
                    <span aria-hidden="true"
                      ><i class="bi bi-chevron-right"></i
                    ></span>
                    <span class="sr-only">Next</span>
                  </a>
                </li>
              </ul>
            </nav>
          </div>
        </div>
        <table
          id="cav-applications-table"
          class=" rounded table table-hover table-sm"
        >
          <thead>
            <th>APP NO</th>
            <th>NAME</th>
            <th>TYPE</th>
            <th>CONTACT NO</th>
            <th>PURPOSE</th>
            <th>DATE APPLIED</th>
            <th>CTC</th>
            <th>STATUS</th>
            <th>LAST UPDATED</th>
          </thead>
          <tbody class="text-uppercase">
            <tr class="text-center" v-show="tableLoader">
              <td colspan="15">
                <div class="spinner-border spinner-border-sm mr-1"></div>
                Loading...
              </td>
            </tr>
            <tr class="text-center" v-if="!apps.length && !tableLoader">
              <td colspan="15">No application/s found.</td>
            </tr>
            <tr
              v-for="app in apps"
              :key="app.id"
              @click="openCavApplicationDetailsModal(app)"
            >
              <th>{{ app.id }}</th>

              <td class="text-left">
                {{ app.firstname }} {{ app.middlename }} {{ app.lastname }}
              </td>
              <td>{{ app.student_type }}</td>

              <td>{{ app.contact_no }}</td>
              <td>{{ app.purpose }}</td>
              <td>{{ app.created_at | completeDateTime }}</td>
              <td>{{ app.ctc_qty }}</td>

              <td>
                <span
                  :class="[
                    'badge ',
                    app.status == 'RELEASED'
                      ? 'badge-success'
                      : 'badge-secondary',
                  ]"
                  >{{ app.status }}</span
                >
              </td>
              <td>
                <span v-show="app.updated_by">{{
                  app.updated_at | dateTime
                }}</span>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <ApplicationFormModal
      ref="ApplicationFormModal"
      v-on:getApps="
        getCavApplications(DEFAULT_URL, {
          tableLoader: { enabled: false },
          searchLoader: { enabled: false },
        })
      "
    ></ApplicationFormModal>
    <CavApplicationDetailsModal
      ref="CavApplicationDetailsModal"
    ></CavApplicationDetailsModal>
  </div>
</template>

<script>
import ApplicationFormModal from './components/ApplicationFormModal.vue';
import CavApplicationDetailsModal from './components/CavApplicationDetailsModal.vue';

export default {
  name: 'CAVApplication',
  components: {
    ApplicationFormModal,
    CavApplicationDetailsModal,
  },
  data() {
    return {
      currentID: '',
      prefix: 'https://drive.google.com/file/d/',
      apps: [],
      tableLoader: false,
      searchLoader: false,
      pagination: {},
      DEFAULT_URL: 'api/cav-applications',
      params: {
        id: '',
        lastname: '',
        status_id: '',
        student_type: '',
        purpose: '',
        created_at: '',
        per_page: 10,
      },
    };
  },
  methods: {
    tabActive(element) {
      this.currentTab = element;
      $('#cav-ul-tabs .nav-link').removeClass('active');
      $('#' + element).addClass('active');
    },

    openUrl(path) {
      let url = this.prefix + path + '/view';
      window.open(url, '_blank');
    },
    openApplicationFormModal() {
      this.$refs.ApplicationFormModal.open();
    },
    openCavApplicationDetailsModal(app) {
      this.$refs.CavApplicationDetailsModal.open(app);
    },
    getCavApplications(page_url, options) {
      this.tableLoader = options.tableLoader.enabled;
      this.searchLoader = options.searchLoader.enabled;
      page_url = page_url || this.DEFAULT_URL;

      const params = {
        id: this.params.id,
        lastname: this.params.lastname,
        status_id: this.params.status_id,
        student_type: this.params.student_type,
        purpose: this.params.purpose,
        created_at: this.params.created_at,
        per_page: this.params.per_page,
      };

      axios
        .get(page_url, {
          params: params,
        })
        .then((res) => {
          this.apps = res.data.data;
          this.makePagination(res.data);
          this.tableLoader = false;
          this.searchLoader = false;
        })
        .catch((err) => {
          this.tableLoader = false;
          this.searchLoader = false;
          toastr.error(
            'Unable to retrieve application/s.',
            'Oops! Something went wrong.'
          );
        });
    },

    getStats() {
      this.resetStats();
      axios
        .get('api/cav-applications/hei/stats')
        .then((res) => {
          let statsArray = res.data;
          statsArray.forEach((e) => {
            this.stats[e.name].count = e.count;
          });
        })
        .catch((err) => {
          if (err.response?.status == 401) {
            this.$store.commit('setAuthentication', false);
            this.$store.commit('setUser', null);
            this.$router.push({ name: 'Login' });
          }
        });
    },
    makePagination(data) {
      let pagination = {
        current_page: data.current_page,
        last_page: data.last_page,
        next_page_url: data.next_page_url,
        prev_page_url: data.prev_page_url,
        from: data.from,
        to: data.to,
        total: data.total,
      };

      this.pagination = pagination;
    },

    resetStats() {
      for (const key in this.stats) {
        this.stats[key].count = 0;
      }
    },
  },
  mounted() {
    this.getCavApplications(this.DEFAULT_URL, {
      tableLoader: { enabled: true },
      searchLoader: { enabled: false },
    });
    this.getStats();
  },
};
</script>

<style scoped>
#cav-applications-table tr {
  cursor: pointer !important;
}
</style>
