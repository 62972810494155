<template>
  <div>
    <div class="modal rounded" id="cav-application-details-modal">
      <div class="modal-dialog" style="max-width: 35%">
        <div class="modal-content rounded">
          <div class="modal-body rounded" style="background-color: #f2f2f2;">
            <div class="card shadow-sm rounde">
              <div class="card-body">
                <p class="font-weight-bold text-uppercase">
                  APP NO: {{ form.id }} ({{ form.created_at | dateTime }})
                </p>

                <div class="">
                  <table class="table table-sm">
                    <tbody>
                      <tr>
                        <td width="30%">Name</td>
                        <th>
                          {{ form.lastname }}, {{ form.firstname }}
                          {{ form.middlename }} ({{ form.student_type }})
                        </th>
                      </tr>

                      <tr>
                        <td>Purpose</td>
                        <th>{{ form.purpose }}</th>
                      </tr>
                      <tr>
                        <td>Email</td>
                        <th>{{ form.email }}</th>
                      </tr>
                      <tr>
                        <td>Contact No</td>
                        <th>{{ form.contact_no }}</th>
                      </tr>

                      <tr>
                        <td>Remarks/Deficiencies</td>
                        <th>
                          <p
                            class="font-weight-bold text-danger"
                            style="white-space:pre-line;"
                          >
                            {{ form.remarks }}
                          </p>
                        </th>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>

            <div class="card rounded shadow-sm">
              <div class="card-body">
                <p class="font-weight-bold">
                  PAYMENT DETAILS
                </p>

                <div class="d-flex justify-content-center">
                  <div
                    v-if="paymentLoader"
                    class="spinner-border text-secondary"
                    role="status"
                  >
                    <span class="sr-only">Loading...</span>
                  </div>
                </div>

                <div class="" v-if="showPaymentDetails">
                  <table class="table table-sm">
                    <tbody>
                      <tr>
                        <td width="30%">CAV Control No</td>
                        <th>
                          {{ payment.control_no }}
                        </th>
                      </tr>
                      <tr>
                        <td>Amound Paid</td>
                        <th>{{ payment.amount }}</th>
                      </tr>
                      <tr>
                        <td>Receipt</td>
                        <th>
                          <span v-if="payment.receipt_date">
                            {{ payment.receipt_no }} -
                            {{ payment.receipt_date | standardDate }}
                          </span>
                        </th>
                      </tr>

                      <tr>
                        <td>Remarks/Deficiencies</td>
                        <th>
                          <p
                            class="font-weight-bold text-danger"
                            style="white-space:pre-line;"
                          >
                            {{ payment.payment_remarks }}
                          </p>
                        </th>
                      </tr>
                    </tbody>
                  </table>
                </div>

                <div v-if="!paymentLoader && !showPaymentDetails">
                  <p>
                    No payment received yet. Make sure that you have uploaded
                    the copy of payment.
                  </p>
                </div>
              </div>
            </div>

            <div class="card rounded shadow-sm">
              <div class="card-body">
                <p class="font-weight-bold">FILE MANAGER</p>
                <div class="d-flex justify-content-center">
                  <div
                    v-if="filesLoader"
                    class="spinner-border text-secondary"
                    role="status"
                  >
                    <span class="sr-only">Loading...</span>
                  </div>
                </div>
                <table id="file-table" v-if="!filesLoader" class="table ">
                  <tbody>
                    <tr v-for="file in files" :key="file.id">
                      <td>{{ file.id }}</td>
                      <td>
                        <a href="#" @click.prevent="openUrl(file.path)">{{
                          file.filename
                        }}</a>
                      </td>
                      <td>{{ file.description }}</td>
                      <td>{{ parseInt(file.size) | prettyBytes }}</td>

                      <td>{{ file.created_at | dateTime }}</td>
                    </tr>
                  </tbody>
                </table>

                <div class="form-group">
                  <i class="bi bi-cloud-arrow-up icon-sm mr-1"></i> Click
                  <a href="#collapse-form" data-toggle="collapse">here</a>
                  to upload file.
                </div>

                <div class="collapse" id="collapse-form">
                  <form @submit.prevent="uploadFile">
                    <div class="">
                      <div class="">
                        <div class="alert alert-warning">
                          <i class="bi bi-exclamation-circle icon-sm mr-1"></i>
                          To expedite the processing of your application, make
                          sure to provide the appropriate file needed.
                        </div>
                        <div class="row">
                          <div class="col">
                            <div class="form-group">
                              <label for="">File Type</label>
                              <select
                                name=""
                                class="form-control"
                                id=""
                                required
                                v-model="file.description"
                              >
                                <option value="" selected disabled hidden
                                  >Choose</option
                                >
                                <option value="AUTH">Authorization</option>
                                <option value="PAY">Payment</option>
                                <option value="COMP">Compliance</option>
                              </select>
                              <small class="form-text text-muted"
                                >Select the appropriat file type.</small
                              >
                            </div>
                          </div>
                          <div class="col">
                            <div class="form-group">
                              <label for="">Select File</label>
                              <input
                                id="upload-file-input"
                                type="file"
                                class="form-control-file"
                                accept="application/pdf"
                                @change="getFile"
                                required
                              />
                              <small class="form-text text-danger"
                                >Make sure to upload PDF files only.</small
                              >
                            </div>
                          </div>
                        </div>

                        <div class="form-group">
                          <button
                            class="btn btn-primary"
                            type="submit"
                            id="upload-file-btn"
                          >
                            UPLOAD
                          </button>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>

            <div class="">
              <button
                type="button"
                class="btn btn-link float-right"
                data-dismiss="modal"
              >
                CLOSE
              </button>
            </div>
          </div>
          <!--  <div class="modal-footer">
            <button type="button" class="btn btn-link" data-dismiss="modal">
              CLOSE
            </button>
          </div> -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'CavApplicationDetailsModal',
  data() {
    return {
      form: new Form({
        id: '',
        firstname: '',
        country_id: '',
        created_at: '',
        payment_id: '',
        received_at: '',
        received_by: '',
        released_by: '',
        lastname: '',
        middlename: '',
        contact_no: '',
        ctc_qty: '',
        date_of_birth: '',
        email: '',
        purpose: '',
        reason: '',
        school_graduated: '',
        sector: '',
        sex: '',
        status_id: '',
        status: '',
        student_type: '',
        updated_at: '',
        updated_by: '',
        remarks: '',
      }),
      files: [],
      prefix: 'https://drive.google.com/file/d/',
      filesLoader: false,
      paymentLoader: false,
      showPaymentDetails: false,
      file: new Form({
        file: '',
        description: '',
      }),
      payment: new Form({
        id: '',
        control_no: '',
        amount: '',
        receipt_no: '',
        receipt_date: '',
        payment_remarks: '',
      }),
    };
  },
  methods: {
    open(app) {
      this.showPaymentDetails = false;
      this.form.fill(app);
      this.payment.reset();
      $('#cav-application-details-modal').modal('show');
      this.getFiles({ filesLoader: { enabled: true } });
      this.getPaymentDetails({ paymentLoader: { enabled: true } });
    },
    getFiles(options) {
      this.filesLoader = options.filesLoader.enabled;
      axios
        .get('api/files/' + this.form.id)
        .then((res) => {
          this.files = res.data;
          this.filesLoader = false;
        })
        .catch(() => {
          toastr.error('Unable to get files.', 'Oops! Something went wrong.');
          this.filesLoader = false;
        });
    },
    getFile(event) {
      this.file.file = event.target.files[0];
    },
    getPaymentDetails(options) {
      if (!this.form.payment_id) return;

      this.paymentLoader = options.paymentLoader.enabled;
      axios
        .get('api/cav-payments/' + this.form.payment_id)
        .then((res) => {
          this.paymentLoader = false;
          this.payment.fill(res.data);
          this.showPaymentDetails = true;
        })
        .catch((err) => {
          this.paymentLoader = false;
          toastr.error(
            'Unable to get payment details.',
            'Oops! Something went wrong.'
          );
        });
    },
    uploadFile() {
      let btn = document.getElementById('upload-file-btn');
      btn.disabled = true;
      btn.innerHTML = `<span class="spinner-border spinner-border-sm mr-1"></span> UPLOADING...`;

      let fd = new FormData();

      fd.append('app_id', this.form.id);
      fd.append('file_type', this.file.description);
      fd.append('file', this.file.file);

      axios
        .post('api/files', fd)
        .then((res) => {
          btn.disabled = false;
          btn.innerHTML = 'UPLOAD';
          $('#collapse-form').collapse('hide');
          this.getFiles({ filesLoader: { enabled: false } });
          this.file.file = '';
          document.getElementById('upload-file-input').value = '';
        })
        .catch((err) => {
          toastr.error(
            'Unable to upload and store file.',
            'Oops! Something went wrong.'
          );
          btn.disabled = false;
          btn.innerHTML = 'UPLOAD';
        });
    },
    openUrl(path) {
      const url = this.prefix + path + '/view';
      window.open(url, '_blank');
    },
  },
};
</script>
